.swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.news-slider {
  padding: 0 60px;
  position: relative;
}

.swiper-button-next, .swiper-button-prev {
  &:focus {
    outline: none;
  }
}

.swiper-button-next, .swiper-button-prev {
  width: 16px;
  height: 29px;
}

.swiper-button-next {
  background: url("images/arrow-right.png");
}

.swiper-button-prev {
  background: url("images/arrow-left.png");
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
