.career {
  ul {
    margin-left: 0;
    padding: 20px;
    font-size: 16px;
    line-height: 24px;
    color: #1A1A1A; }

  h3 {
    font-size: 20px;
    line-height: 24px;
    color: #3E3E3E;
    font-weight: 600; } }
