.privacy-table {
  width: 100%;

  th {
    padding: 20px;
    background-color: #292929;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: normal;
    font-size: 16px;

    &:first-child {
      width: 200px; } }

  tr {
    border: 1px solid #dddddd; }

  td {
    padding: 20px;
    font-weight: 400;
    line-height: normal;
    font-size: 14px; }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: $gray-200; }

      &:hover {
        background-color: $gray-400; } } } }

