$input-duration: .2s;
$border-width: 2px;
$active-border-width: 2px;

// General for radios and checkboxes
.option-box {
  position: relative;
  margin-bottom: 10px;
  //padding-top: 1px;
  padding-left: 30px;
  @include respond-to(xs) {
    margin-bottom: 20px;
  }

  input {
    // hiding input
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;

    ~ label {
      .option-box_icon {
        position: absolute;
        &:before,
        &:after {
          content: "";
          position: absolute;
          pointer-events: none;
          opacity: 0;
          transition: opacity $input-duration;
          width: 17px;
          height: 16px;
        }
      }

      .option-box_icon,
      .option-box_icon:after {
        border: solid $border-width #797979;
        background: linear-gradient(0deg, #F6F7F9 0%, #FFFFFF 100%);
      }

      .option-box_icon:after {
        border-width: $active-border-width;
        top: $border-width * -1;
        right: $border-width * -1;
        bottom: $border-width * -1;
        left: $border-width * -1;
      }

      &:hover {
        .option-box_icon,
        .option-box_icon:after {
          border-color: #797979;
        }
      }
    }


    &:checked,
    &[checked="checked"] {
      ~ label {
        .option-box_icon,
        .option-box_icon:after {
          border-color: #797979;
        }
        .option-box_icon:before {
          opacity: 1;
        }
      }
    }

    &:active {
      ~ label {
        .option-box_icon,
        .option-box_icon:after {
          border-color: #797979;

        }
        .option-box_icon:after {
          opacity: 1;
        }
      }
    }

    &:disabled {
      ~ label {
        cursor: default;
        .option-box_icon,
        .option-box_icon:after {
          border-color: #d6d6d6;
        }
        .option-box_icon:after {
          opacity: 0 !important;
        }
      }
    }
  }

  label {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
    cursor: pointer;
    color: $main-color-3;
    i {
      color: $red;
    }
  }
}


// chekboxes
.option-box {
  $checkbox-side: 17px;
  $checkbox-left-offset: 0px;
  $checkbox-top-offset: 0;
  //$border-radius: 3px;
  $sprite-width: 17px;
  $sprite-height: 16px;

  input[type="checkbox"] {
    ~ label {
      .option-box_icon {
        width: $checkbox-side;
        height: $checkbox-side;
        left:  $checkbox-left-offset;
        top: $checkbox-top-offset;
        height: 16px;
        top: 3px;
        &:before {
          background: url("images/checkbox-active.svg");
          //top: ($checkbox-side - ($sprite-height + $border-width * 2)) / 2 + $checkbox-top-offset;
          //left: ($checkbox-side - ($sprite-width + $border-width * 2)) / 2 + $checkbox-left-offset;
          top: -2px;
          left: -2px;
        }
      }
      .option-box_icon,
      .option-box_icon:after {
        //border-radius: $border-radius;
      }
    }

    &:disabled {
      ~ label {
        .option-box_icon {
          &:before {
            background: url("images/checkbox-inactive.svg");
          }
        }
      }
    }
  }

}

// radio buttons

.option-box {
  $radio-side: 17px;
  $radio-left-offset: 0px;
  $radio-top-offset: 0;
  $border-radius: 50%;
  $sprite-width: 17px;
  $sprite-height: 16px;

  input[type="radio"] {
    ~ label {
      .option-box_icon {
        width: $radio-side;
        width: 17px;
        height: $radio-side;
        left:  $radio-left-offset;
        top: $radio-top-offset;
        top: 3px;
        &:before {
          background: url("images/radio-active.svg");
          top: ($radio-side - ($sprite-height + $border-width * 2)) / 2 + $radio-top-offset;
          left: ($radio-side - ($sprite-width + $border-width * 2)) / 2 + $radio-left-offset;
          left: -2px;
        }
      }
      .option-box_icon,
      .option-box_icon:after {
        border-radius: $border-radius;
      }
    }

    &:disabled {
      ~ label {
        .option-box_icon {
          &:before {
            background: url("images/radio-inactive.svg");
          }
        }
      }
    }
  }

}
