h1, h2 {
  font-size: 70px;
  line-height: 90px;
  color: $main-color-2;
  font-weight: 300;
  margin-bottom: 60px;
  text-align: center;
  text-transform: none;
  @include respond-to(md) {
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 20px;
  }
  @include respond-to(mobile) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
    text-align: left;
  }
}

.scroll {
  width: 57px;
  height: 39px;
  margin: 0 auto 40px;
  transition: 500ms all;
  opacity: 1;
  position: relative;
  @include respond-to(xs) {
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("images/scroll.png");
    background-size: 100%;
  }
}

.scroll.active {
  opacity: 0;
  transform: translateY(40px);
}

.nsw,
.ifb {
  h2 {
    @include respond-to(mobile) {
      text-align: center;
    }
  }
}

.tsb {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  @include respond-to(mobile) {
    margin-bottom: 40px;
  }

  img {
    max-width: 100%;
  }

  h1 {
    font-size: 56px;
    line-height: 76px;
    font-weight: 300;
    color: $main-color-2;
    margin-bottom: 40px;
    text-align: left;
    @include respond-to(md) {
      font-size: 42px;
      line-height: 48px;
      margin-bottom: 20px;
    }
    @include respond-to(mobile) {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: $main-color-3;
    margin-bottom: 36px;
    opacity: 0.8;
    @include respond-to(mobile) {
      margin-bottom: 30px;
    }
  }

  .btn {
    @include respond-to(mobile) {
      margin-bottom: 30px;
    }
  }
}

.ifb {
  text-align: center;
  margin-bottom: 80px;

  &-text {
    font-size: 16px;
    line-height: 24px;
    color: $main-color-3;
    opacity: 0.8;
    font-weight: 300;
    @include respond-to(desktop) {
      max-width: 770px;
      margin: 0 auto 60px;
    }
    @include respond-to(mobile) {
      max-width: 470px;
      margin: 0 auto 40px;
    }
  }

  &_item {
    text-align: center;
    position: relative;
    @include respond-to(sm-md-lg) {
      max-width: 250px;
      margin: 0 auto;
    }
    @include respond-to(mobile) {
      margin-bottom: 30px;
    }

    &-img {
      width: 121px;
      height: 121px;
      background: $orange;
      border-radius: 50%;
      margin: 0 auto 30px;
      position: relative;
      @include respond-to(mobile) {
        margin-bottom: 15px;
      }
      @include respond-to(mobile) {
        width: 60px;
        height: 60px;
        background-color: transparent;
        &.-banners {
          &:after {
            width: 40px;
            height: 40px;
            background: url("images/info-icons/m-banners.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-popunder {
          &:after {
            width: 41px;
            height: 39px;
            background: url("images/info-icons/m-popun.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-skimmed {
          &:after {
            width: 44px;
            height: 44px;
            background: url("images/info-icons/m-skimmed.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-native {
          &:after {
            width: 38px;
            height: 38px;
            background: url("images/info-icons/m-native.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-self {
          &:after {
            width: 48px;
            height: 45px;
            background: url("images/info-icons/m-self.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-formats {
          &:after {
            width: 37px;
            height: 55px;
            background: url("images/info-icons/m-formats.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-tracking {
          &:after {
            width: 61px;
            height: 42px;
            background: url("images/info-icons/m-tracking.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-targeting {
          &:after {
            width: 48px;
            height: 48px;
            background: url("images/info-icons/m-targetting.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-monitoring {
          &:after {
            width: 48px;
            height: 48px;
            background: url("images/info-icons/m-monitoring.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-traffic {
          &:after {
            width: 48px;
            height: 48px;
            background: url("images/info-icons/m-traffic.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-buyers {
          &:after {
            width: 48px;
            height: 48px;
            background: url("images/info-icons/m-buyers.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-instruments {
          &:after {
            width: 48px;
            height: 48px;
            background: url("images/info-icons/m-instruments.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-payouts {
          &:after {
            width: 66px;
            height: 66px;
            background: url("images/info-icons/m-payouts.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-ads {
          &:after {
            width: 70px;
            height: 77px;
            background: url("images/info-icons/m-ads.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-flexible {
          &:after {
            width: 63px;
            height: 40px;
            background: url("images/info-icons/m-flexible.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-rates {
          &:after {
            width: 62px;
            height: 65px;
            background: url("images/info-icons/m-crm.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-coverage {
          &:after {
            width: 54px;
            height: 54px;
            background: url("images/info-icons/m-coverage.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-support {
          &:after {
            width: 54px;
            height: 54px;
            background: url("images/info-icons/m-support.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-reports {
          &:after {
            width: 61px;
            height: 33px;
            background: url("images/info-icons/m-reports.svg") no-repeat;
            background-size: 100%;
          }
        }
        &.-referral {
          &:after {
            width: 51px;
            height: 51px;
            background: url("images/info-icons/m-referral.svg") no-repeat;
            background-size: 100%;
          }
        }
      }

      &:after {
        content: '';
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    &-title {
      font-size: 24px;
      line-height: 32px;
      color: $main-color-2;
      font-weight: 600;
      margin-bottom: 30px;
      letter-spacing: 0.341333px;
      @include respond-to(mobile) {
        font-size: 20px;
        line-height: 30px;
      }
      @include respond-to(sm) {
        margin-bottom: 20px;
      }
      @include respond-to(xs) {
        margin-bottom: 10px;
      }
    }


    &-description {
      font-size: 14px;
      line-height: 24px;
      color: $main-color-3;
      opacity: 0.8;

      strong {
        font-weight: 600;
      }
    }
  }

  h2 {
    margin-bottom: 40px;
  }

  @include respond-to(desktop) {
    .-banners {
      &:after {
        width: 53px;
        height: 53px;
        background: url("images/info-icons/banners.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-popunder {
      &:after {
        width: 53px;
        height: 53px;
        background: url("images/info-icons/popun.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-skimmed {
      &:after {
        width: 57px;
        height: 56px;
        background: url("images/info-icons/skimmed.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-native {
      &:after {
        width: 53px;
        height: 53px;
        background: url("images/info-icons/native.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-ads {
      &:after {
        width: 70px;
        height: 77px;
        background: url("images/info-icons/safe.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-rates {
      &:after {
        width: 62px;
        height: 66px;
        background: url("images/info-icons/crm.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-payouts {
      &:after {
        width: 66px;
        height: 66px;
        background: url("images/info-icons/payouts.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-support {
      &:after {
        width: 58px;
        height: 58px;
        background: url("images/info-icons/support.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-formats {
      &:after {
        width: 51px;
        height: 74px;
        background: url("images/info-icons/formats.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-tracking {
      &:after {
        width: 83px;
        height: 58px;
        background: url("images/info-icons/tracking.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-traffic {
      &:after {
        width: 48px;
        height: 71px;
        background: url("images/info-icons/traffic.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-targeting {
      &:after {
        width: 60px;
        height: 60px;
        background: url("images/info-icons/targetting.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-self {
      &:after {
        width: 56px;
        height: 52px;
        background: url("images/info-icons/self.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-monitoring {
      &:after {
        width: 55px;
        height: 55px;
        background: url("images/info-icons/monitoring.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-buyers {
      &:after {
        width: 66px;
        height: 62px;
        background: url("images/info-icons/buyers.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-instruments {
      &:after {
        width: 65px;
        height: 56px;
        background: url("images/info-icons/instruments.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-flexible {
      &:after {
        width: 64px;
        height: 41px;
        background: url("images/info-icons/flexible.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-coverage {
      &:after {
        width: 54px;
        height: 54px;
        background: url("images/info-icons/coverage.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-reports {
      &:after {
        width: 75px;
        height: 41px;
        background: url("images/info-icons/reports.svg") no-repeat;
        background-size: 100%;
      }
    }
    .-referral {
      &:after {
        width: 64px;
        height: 64px;
        background: url("images/info-icons/referral.svg") no-repeat;
        background-size: 100%;
      }
    }
  }


  .btn {
    margin-top: 80px;
    @include respond-to(mobile) {
      margin-top: 10px;
    }
    @include respond-to(xs) {
      width: 100%;
    }
  }
}


.svb {
  margin-bottom: 110px;
  @include respond-to(mobile) {
    margin-bottom: 90px;
  }

  &_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &-img {
      @include respond-to(sm) {
        width: 25%;
        margin: 0 auto 20px;
        text-align: center;
      }
      @include respond-to(xs) {
        width: 50%;
        margin: 0 auto 40px;
        text-align: center;
      }
      @media only screen and (max-width: 430px) {
        width: 100%;
      }
    }
  }
}

.news-slider {
  @include respond-to(lg) {
    min-height: 434px;
  }
  @include respond-to(md) {
    min-height: 385px;
  }
}

.nsw {
  text-align: center;
  margin-bottom: 70px;

  &_item {
    text-align: left;

    &-img {
      margin-bottom: 20px;
      display: block;
      position: relative;
      padding-top: 70%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }

    &-name {
      font-size: 20px;
      line-height: 30px;
      color: $main-color-2;
      margin-bottom: 20px;
      text-decoration: none;
      display: block;
      font-weight: 600;

      &:hover {
        text-decoration: none;
        color: $main-color-2 !important;
      }
    }

    &-description {
      font-size: 14px;
      line-height: 21px;
      opacity: 0.8;
      font-weight: 400;
      color: $main-color-3;
      margin-bottom: 30px;
    }

    &-date {
      font-size: 12px;
      line-height: 18px;
      opacity: 0.8;
      font-weight: 400;
      letter-spacing: 2px;
      color: $main-color-3;
      margin-bottom: 20px;
    }
  }

  .btn {
    margin-top: 20px;
    @include respond-to(xs) {
      width: 100%;
    }
  }
}

.main-header {
  .col-sm-5 {
    margin-top: -60px;
    @include respond-to(sm-md-lg) {
      h1 {
        overflow: auto;
        width: 600px;
        z-index: 20;
        position: relative;
      }
    }
  }
}

.animation {
  @include respond-to(desktop) {
    position: relative;
    left: -20px;
  }
}

.img-to-remove {
  @media only screen and (max-width: 1200px) {
    width: 73%;
    margin-left: 50px;
  }
}
