@media only screen and (max-width:1023px) {
  .rfp .trafficshop-box {
    margin-top: -60px;
  }
}
@media only screen and (min-width:1024px) {
  .rfp .trafficshop-box {
    margin-top: 0px;
  }
}


.rfp .img-col {
  display: flex;
  justify-content: flex-end
}

@media only screen and (min-width:1280px) {
  .rfp .img-col img {
    max-width: 730px;
    max-height: 368px;
    margin-top: 45px;
  }
}

@media only screen and (min-width:1024px) and (max-width:1279px) {
  .rfp .img-col img {
    max-width: 430px;
    max-height: 217px;
  }
}

.rfp .steps {
  padding: 50px;
  background-color: #fff
}

@media only screen and (max-width:1023px) {
  .rfp .steps {
    padding: 35px 35px 100px
  }
}

.rfp .steps .btn {
  margin-top: 60px
}

.rfp .steps .abs-title {
  margin-bottom: 50px
}

@media only screen and (max-width:1023px) {
  .rfp .steps .s_i {
    padding-bottom: 80px
  }
}

@media only screen and (max-width:1023px) {
  .rfp .steps .s_i.-last {
    padding-bottom: 0
  }
}

@media only screen and (max-width:1023px) {
  .rfp .steps .s_i-number {
    margin-bottom: 15px
  }
}

@media only screen and (max-width:1023px) {
  .rfp .steps .s_i-arrow:after {
    left: 0;
    right: 0;
    margin: auto;
    top: -50px;
    transform: rotate(90deg)
  }
}

.rfp_top-text {
  max-width: 1050px;
  text-align: center;
  margin: 0 auto 40px
}

.rfp_top-text span {
  font-weight: 400
}

.rfp_top-text h2 {
  font-size: 42px;
  line-height: 48px;
}

@media only screen and (min-width:1024px) {
  .rfp_top-text h2 {
    margin-left:120px;
    margin-right:120px;
  }
}

@media only screen and (max-width:1023px) {
  .rfp_top-text h2 {
    font-size: 28px;
    line-height: 36px;
    text-align: center
  }
}

.rfp_top-text p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #1A1A1A;
  opacity: 0.8;

  max-width: 400px;
  margin: 0 auto;

}

.rfp .tsb p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #1A1A1A;
  opacity: 0.8;
}

@media only screen and (min-width:1024px) {
  .rfp .tsb {
    margin-bottom: 100px
  }

  .rfp .tsb h1{
    width: 550px;
  }

  .rfp .tsb p{
    width: 450px;
  }
}

@media (max-width:640px) {
  .rfp .tsb .btn {
    width: 100%
  }
}

.rfp .plb {
  margin: 0 auto
}

@media only screen and (min-width:1024px) {
  .rfp .plb {
    margin-bottom: 80px
  }
}

.rfp .plb-item {
  max-width: 1050px;
  margin: 0 auto 50px
}

@media only screen and (max-width:767px) {
  .rfp .plb-item {
    text-align: center
  }
}

.rfp .plb-item img {
  max-width: 100%
}

@media only screen and (max-width:767px) {
  .rfp .plb-item img {
    margin-bottom: 40px
  }
}

@media only screen and (max-width:767px) {
  .rfp .plb .-right-img {
    flex-direction: column-reverse
  }
}

.rfp .plb h2 {
  font-size: 42px;
  line-height: 48px;
  max-width: 943px;
  margin: 0 auto 60px
}

@media only screen and (max-width:1023px) {
  .rfp .plb h2 {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 40px
  }
}

.rfp .plb h3 {
  font-size: 24px;
  line-height: 28px;
  color: #333;
  letter-spacing: .34px;
  font-weight: 600;
  margin-bottom: 30px
}

.rfp .plb p {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  opacity: .6
}

@media only screen and (max-width:1023px) {
  .rfp .plb p {
    font-size: 14px;
    line-height: 24px
  }
}

@media only screen and (max-width:1023px) {
  .rfp-bottom {
    padding: 0
  }
}

.rfp-b {
  padding: 60px 40px 10px;
  background-color: #fff;
  text-align: center;
  width: 100%;
  margin-bottom: 60px
}

@media only screen and (max-width:1023px) {
  .rfp-b {
    padding: 35px 15px 20px
  }
}

.rfp-b_content {
  max-width: 910px;
  margin: 0 auto
}

.rfp-b_main-text {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: .34px;
  color: #333;
  font-weight: 600;
  margin-bottom: 30px
}

.rfp-b_text {
  font-size: 14px;
  line-height: 24px;
  color: #1a1a1a;
  opacity: .6;
  margin-bottom: 40px
}

.rfp-b .btn {
  margin-bottom: 50px
}

.rfp-b_last {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: .34px;
  color: #333
}