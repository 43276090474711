.ftr {
  background-color: #292929;
  &-top {
    margin-bottom: 10px;
  }
  &-main {

    padding: 40px 0 0;
  }
  &-bottom {
    padding: 50px 0 40px;
  }
  .xs-480 {
    @include respond-to(xs480) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  &_manager {
    margin-bottom: 30px;
    &-name {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: #63707B;
      margin-bottom: 10px;
    }
    &-contacts {
      font-size: 14px;
      line-height: 24px;
      color: $white;
      margin-bottom: 5px;
      font-weight: 600;
    }
    &-contacts {
      span {
        font-weight: 300;
      }
      a {
        text-decoration: none;
        color: $white;
        font-weight: 300;
      }
    }
  }
  &_support {
    @include respond-to(lg) {
      margin-bottom: 30px;
    }
    @include respond-to(xs) {
      margin-bottom: 30px;
    }
    &-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: #63707B;
      margin-bottom: 5px;
    }
    &-info, a {
      font-size: 16px;
      line-height: 24px;
      color: $white;
      font-weight: 300;
    }
  }
  &_payment {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    @include respond-to(xs) {
      margin-bottom: 30px;
    }
    img {
      margin-right: 30px;
      @include respond-to(xs) {
        margin-bottom: 0;
      }
    }
  }
  &_cgbilling {
    color: $white;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 24px;
    a {
      color: $white;
    }
  }
  &_address {
    margin-bottom: 25px;
    @include respond-to(md) {
      margin-bottom: 30px;
    }
    @include respond-to(sm) {
      margin-bottom: 30px;
    }
    @include respond-to(xs) {
      margin-bottom: 30px;
    }
    &-title {
      font-size: 16px;
      line-height: 24px;
      color: #63707B;
      margin-bottom: 5px;
    }
    &-description, a {
      font-size: 14px;
      line-height: 24px;
      color: $white;
    }
  }

  &_social-icons {
    a {
      position: relative;
      width: 50px;
      height: 50px;
      display: inline-block!important;
      margin-right: 20px;
      &:after {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
      &:hover {
        &:after {
          opacity: 0.5;
        }
      }
      &.-s-facebook {
        &:after {
          background: url("images/facebook.svg");
          background-size: 100%;
        }
      }
      &.-s-twitter {
        &:after {
          background: url("images/twitter.svg");
          background-size: 100%;
        }
      }
      &.-s-linkedin {
        &:after {
          background: url("images/linkedin.svg");
          background-size: 100%;
        }
      }
    }
  }

  &-nav {
    @include respond-to(mobile) {
      margin-bottom: 30px;
    }
    @include respond-to(md) {
      margin-bottom: 30px;
    }
    &-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: #63707B;
      margin-bottom: 10px;
    }
    li {
      list-style: none;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    a {
      font-size: 14px;
      line-height: 18px;
      color: $white;
      &:not(:last-child) {
        @include respond-to(lg) {
          margin-right: 30px;
        }
        @include respond-to(md) {
          margin-right: 10px;
        }
      }
    }
  }
  .logo {
    width: 203px;
    @include respond-to(xs) {
      margin-bottom: 20px;
      display: inline-block;
    }
    img{
      width: 203px;
    }
  }
  &-copyright {
    font-size: 12px;
    line-height: 18px;
    color: $white;
    font-weight: 300;
    @include respond-to(md) {
      text-align: right;
    }
  }
}

.ftr {
  &-bottom-small {
    background: #212121;
    padding: 20px 0;
    .ftr-nav {
      margin-bottom: 0!important;
      a {
        @include respond-to(md) {
          font-size: 11px;
          line-height: 15px;
        }
      }
    }
    .logo {
      @include respond-to(xs) {
        margin-bottom: 20px;
      }
    }
    .ftr-copyright {
      @include respond-to(xs) {
        text-align: center;
      }
    }
  }
}
