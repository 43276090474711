.spt {
  margin-bottom: 60px;
  @include respond-to(desktop) {
    max-width: 657px;
    margin: 0 auto 60px;
  }
  h1 {
    @include respond-to(mobile) {
      margin-bottom: 20px;
    }
  }
  &-title {
    font-size: 24px;
    list-height: 36px;
    color: $main-color-2;
    font-weight: 600;
    margin-bottom: 15px;
    @include respond-to(mobile) {
      font-size: 20px;
    }
    &-sub {
      font-size: 20px;
    }
    + a {
      font-size: 16px;
      font-weight: 600!important;
      border-bottom: 1px solid #fdc89e;
      margin-bottom: 40px;
      @include respond-to(mobile) {
        margin-bottom: 30px;
      }
      display: inline-block;
      &:hover {
        text-decoration: none;
        border-color: $orange;
      }
    }
  }
  &-name {
    font-size: 20px;
    line-height: 30px;
    color: $main-color-2;
    font-weight: 600;
    margin-bottom: 0;
    @include respond-to(mobile) {
      font-size: 18px;
      margin-bottom: 5px;
    }
  }
  &-info {
    font-size: 14px;
    line-height: 28px;
    color: $main-color-3;
    font-weight: 300;
    span {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 11px;
      letter-spacing: 1.5px;
    }
    strong {
      font-weight: 600!important;
    }
    .-email {
      color: $main-color-2;
      font-weight: 600;
    }
    margin-bottom: 40px;
    @include respond-to(mobile) {
      margin-bottom: 30px;
    }
  }
  &-text {
    font-size: 14px;
    line-height: 24px;
    color: $main-color-3;
    opacity: 0.8;
    a {
      border-bottom: 1px solid #fdc89e;
      &:hover {
        text-decoration: none;
        border-color: $orange;
      }
    }
  }
}

.stb {
  .center {
    @include respond-to(mobile) {
      text-align: left;
    }
  }
}
