.log-in {
  @include respond-to(sm-md-lg) {
    max-width: 370px;
    margin: 0 auto;
  }
  input[type="submit"] {
    width: 100%;
    margin-bottom: 20px;
  }
  &-msg {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: $gray-light;
    margin-bottom: 40px;
    @include respond-to(xs) {
      text-align: left;
    }
    a {
      color: $orange;
      font-weight: 600;
      text-decoration: underline;
      text-transform: uppercase;
    }
  }
}

.reset-pass {
  &-wrap {
    @include respond-to(sm-md-lg) {
      max-width: 370px;
      margin: 0 auto;
      text-align: center;
    }
  }
  .field-group {
    margin-bottom: 15px;
  }
  .btn {
    width: 100%;
    margin-bottom: 50px;
  }
}

.log-in,
.reset-pass {
  h2 {
    @include respond-to(sm) {
      text-align: center;
    }
  }
}


.sign-up {
  margin-bottom: 60px;
  @include respond-to(sm-md-lg) {
    max-width: 570px;
    margin: 0 auto 60px;
  }
  input[type="submit"] {
    @include respond-to(mobile) {
      width: 100%;
    }
  }

  .bold-text {
    font-size: 14px;
    line-height: 21px;
    color: $main-color-3;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .small-text {
    font-size: 12px;
    line-height: 18px;
    color: $main-color-3;
    margin-bottom: 10px;
    @include respond-to(xs) {
      margin-bottom: 20px;
    }
  }
}
