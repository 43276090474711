.s-g-lang {
  margin-right: 5px;
  position: relative;
  min-width: 40px;
  @include respond-to(mobile) {
    margin-left: auto;
    margin-right: auto;
  }
  &.is-opened {
    .s-g-lang_title {
      &:after {
        transform: rotate(-180deg) scale(1) skew(0deg) translate(0px)
      }
    }
    .s-g-lang_dropdown {
      display: block;
    }
  }
  &_title {
    font-size: 11px;
    font-weight: 700;
    color: #292929;
    text-transform: uppercase;
    position: relative;
    padding-right: 12px;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 0.05em;
    &:after {
      transition: transform 0.25s ease-in-out;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 9px;
      height: 5px;
      background: url("images/arrow-down.svg");
      background-size: 100%;
    }
  }
  &_dropdown {
    display: none;
    position: absolute;
    top: 25px;
    left: 0;
    z-index: 1000;
    @include respond-to(mobile) {
      top: -15px;
      transform: translateY(-100%);
    }
    &-result-item {
      min-width: 126px;
      padding: 10px 15px 10px 15px;
      @include respond-to(xs) {
        padding: 8px 15px;
      }
      background-color: $white;
      font-size: 14px;
      line-height: 21px;
      color: $main-color-3;
      font-weight: 400;
      border-bottom: 1px solid #d8d8d8;
      cursor: pointer;
    }
  }
}
