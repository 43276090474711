.content-wrapper {

  .date{
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 2.5px;
    color: #1A1A1A;
  }

  margin-bottom: 60px;
  @include respond-to(desktop) {
    max-width: 769px;
    margin: 0 auto 60px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
    color: $main-color-3;
    margin-bottom: 10px;
    font-weight: 300;
    @include respond-to(mobile) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .small {
    font-size: 12px;
    a {
      color: $main-color-3;
      &:hover {
        color: $main-color-3;
      }
    }
  }
  .center {
    text-align: center;
  }
  strong {
    font-weight: 600;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    color: $main-color-1;
    font-weight: 600;
    margin: 40px 0;
    @include respond-to(mobile) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  h1, h2 {
    @include respond-to(mobile) {
      margin-bottom: 20px;
    }
  }
  a {
    font-weight: 400;
    border-bottom: 1px solid #fdc89e;
    &:hover {
      text-decoration: none;
      border-color: $orange;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  ul, ol {
    @extend p;
    margin-left: 0;
    padding-left: 20px;
  }
}




