.per {
  margin-bottom: 60px;
  @include respond-to(desktop) {
    text-align: center;
  }
  &-text {
    font-size: 16px;
    line-height: 24px;
    color: $main-color-3;
    font-weight: 300;
    margin-bottom: 60px;
    @include respond-to(mobile) {
      margin-bottom: 30px;
    }
  }
  .btn {
    margin-bottom: 50px;
    @include respond-to(mobile) {
      margin-bottom: 20px;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    mix-blend-mode: multiply;
  }
  h1 {
    @include respond-to(mobile) {
      margin-bottom: 20px;

    }
  }
}
