.hdr {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  @include respond-to(xs) {
    padding: 13px 0;
  }

  .logo {
    display: inline-block;
    width: 203px;
  }

  &-nav {
    ul {
      display: flex;
      list-style: none;
    }

    li {
      line-height: 1;
      padding: 0 10px;
      position: relative;
      @include respond-to(md) {
        padding: 0 8px;
        &:last-child {
          padding-right: 0;
        }
      }
      &:first-child {
        a {
          text-align: right;
        }
      }
      &:last-child {
        a {
          text-align: left;
        }
      }

      &:not(:last-child) {
        &:after {
          content: '';
          width: 1px;
          height: 21px;
          background-color: $main-color-1;
          opacity: 0.1;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    a {
      display: inline-block;
      text-align: center;
      font-size: 11px;
      color: $main-color-1;
      font-weight: 500;
      transition: all 100ms;
      text-transform: uppercase;
      white-space: nowrap;
      letter-spacing: 0.165em;

      &:hover {
        font-weight: 700;
      }

      &:before {
        display: block;
        content: attr(title);
        font-weight: 700;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }

  .btn {
    padding: 13px 15px 12px;
    min-width: 109px;
    @include respond-to(md) {
      min-width: 80px;
    }
    @include respond-to(sm-md-lg) {
      &:not(:first-child) {
        margin-left: 20px;

      }
    }
  }

  &_navm {
    .s-g-lang {
      margin-bottom: 15px;
    }

    @media (orientation: landscape) {
      .hdr_navm-btn {
        padding-bottom: 20px;
      }
    }

    &-btn {
      display: flex;
      justify-content: center;
    }

    .btn {
      @include respond-to(xs) {
        font-size: 11px;
        min-width: 100px;
      }

      &:not(:first-child) {
        margin-left: 10px;
      }
    }

    background-color: #f4f4f4;
    z-index: 1000;

    &-inner {
      text-align: center;
      display: block;
      position: absolute;
      z-index: 3;
      top: 60px;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      height: auto;
      overflow-y: auto;
      @include respond-to(sm) {
        top: 90px;
      }
      @include respond-to(xs) {
        top: 60px;

      }
      //@media (orientation: portrait) and (max-width: 767px) {
      //  top: 20px;
      //}
    }

    nav {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0px 20px 0;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      max-width: 980px;
      overflow-y: auto;
    }

    .h100 {
      @include respond-to(sm) {
        height: 100%;
      }
      //@media (orientation: portrait) and (max-width: 767px) {
      //  height: 100%;
      //}
    }

    ul {
      text-align: center;
      //@include respond-to(xs) {
      //  margin-bottom: 10px;
      //}
      //@include respond-to(sm) {
      //  margin-bottom: 30px;
      //}
    }

    ul, li {
      list-style: none;
      z-index: 2;
    }

    li {
      @include respond-to(xs) {
        margin-bottom: 12px;
      }
      @include respond-to(sm) {
        margin-bottom: 30px;
      }

      &:not(:last-child) {
        //@media only screen and (max-width: 480px) {
        //  margin-bottom: 15px!important;
        //}
        //@include respond-to(xs480) {
        //  margin-bottom: 30px!important;
        //}
      }
    }

    a {
      font-size: 18px;
      color: $black;
      font-weight: 600;

      &.-gray {
        color: $gray-light;
        font-size: 14px;

      }
    }

    &-btn {
      @include respond-to(sm) {
        display: none;
      }
    }

    .nav-bg {
      position: relative;
      width: 427px;
      height: 475px;
      background: url("images/bg-menu.png");
      background-repeat: no-repeat;
      bottom: 100px;
      right: 0;
      z-index: -1;
      pointer-events: none;
      user-select: none;
      display: grid;
      @media only screen and (max-width: 480px) {
        bottom: -242px;
        right: -152px;
        background-size: 76%;
      }
      @media only screen and (min-width: 481px) {
        width: 100%;
        bottom: -100px;
        right: -380px;
      }

    }

  }
}

.btn-menu {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  z-index: 1001;
  @include respond-to(xs) {
    top: 10px;
  }
  @include respond-to(sm) {
    top: 20px;
  }
}

.hamburguer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  background: $main-color-1;
  width: 30px;
  height: 2px;
  transition: .5s ease-in-out;
}

.hamburguer:before,
.hamburguer:after {
  background: $main-color-1;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: .5s ease-in-out;
}

.hamburguer:before {
  top: -10px;
}

.hamburguer:after {
  bottom: -10px;
}

input#menu-hamburguer {
  display: none;
}

input#menu-hamburguer ~ label {
  margin: 0;
  @include respond-to(desktop) {
    display: none;
  }
}

input#menu-hamburguer:checked ~ label .hamburguer {
  transform: rotate(45deg);
}

input#menu-hamburguer:checked ~ label .hamburguer:before {
  transform: rotate(90deg);
  top: 0;
}

input#menu-hamburguer:checked ~ label .hamburguer:after {
  transform: rotate(90deg);
  bottom: 0;
}

input#menu-hamburguer:checked ~ .hdr_navm {
  opacity: 1;
  height: 100%;
  //overflow: initial;
}

.hdr_navm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  overflow: hidden;
  z-index: 100;
  width: 100%;
  height: 0;
  transition: 700ms all;
  padding: 60px 20px 14px;
  margin: 0 auto;
}
