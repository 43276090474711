.message {
  width: 100%;
  display: block;
  border: 1px solid #f3e6cc;
  background-color: #f3e6cc;
  color: $orange;
  font-size: 15px;
  padding: 15px;
  margin: 20px 0px; }

