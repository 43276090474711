.nlp {
  @include respond-to(desktop) {
    max-width: 654px;
    margin: 0 auto;
  }
    @include respond-to(mobile) {
      margin-bottom: 0;
    }
    &_item {
      margin-bottom: 50px;
      @include respond-to(mobile) {
        margin-bottom: 30px;
      }
      &-img {
        display: block;
        margin-bottom: 10px;
        @include respond-to(mobile) {
          margin-bottom: 15px;
          max-width: 100%;
        }
        @include respond-to(xs) {
          padding-top: 0;
          height: auto;
        }
        img {
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
      &-name {
        font-weight: 500;
        letter-spacing: 0.341333px;
        @include respond-to(mobile) {
          margin-bottom: 15px;
        }
        font-size: 20px;
        line-height: 30px;
        color: $main-color-2;
        margin-bottom: 20px;
        text-decoration: none;
        display: block;

        &:hover {
          text-decoration: none;
          color: $main-color-2 !important;
        }
      }
      &-date {
        letter-spacing: 2.5px;
        @include respond-to(mobile) {
          margin-bottom: 15px;
        }
      }
      &-description {
        margin-bottom: 10px;
        @include respond-to(mobile) {
          margin-bottom: 10px;
        }
        .content-wrapper {
          line-height: 22px;
          margin-bottom: 0;
        }
      }
    }

}

.onp {
  @include respond-to(desktop) {
    max-width: 769px;
    margin: 0 auto;
  }
  p {
    font-weight: 300;
    margin-bottom: 20px;
    line-height: 24px;
    strong {
      font-weight: 600;
    }
  }
  h2 {
    font-size: 1.7em;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 20px;
    letter-spacing: normal;
  }
  h3 {
    font-size: 1.3em;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 1.1em;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  blockquote {
    padding-left: 20px;
    border-left: grey solid 2px;
    font-style: italic;
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 0;
  }
  @include respond-to(mobile) {
    margin-bottom: 30px;
  }
  &_item {
    &-name {
      font-weight: 600;
      letter-spacing: 0.341333px;
      @include respond-to(mobile) {
        margin-bottom: 15px;
      }
      @include respond-to(desktop) {
        text-align: center;
      }
      font-size: 20px;
      line-height: 30px;
      color: $main-color-2;
      margin-bottom: 20px;
      text-decoration: none;
      display: block;
    }
    &-date {
      @include respond-to(desktop) {
        text-align: center;
      }
      font-size: 12px;
      line-height: 18px;
      opacity: 0.8;
      font-weight: 400;
      letter-spacing: 2px;
      color: #1A1A1A;
      margin-bottom: 20px;
    }
    &-img {
      margin-bottom: 10px;
      @include respond-to(mobile) {
        margin-bottom: 15px;
      }

    }
    &-name {
      @include respond-to(mobile) {
        margin-bottom: 15px;
      }
    }
    &-date {
      @include respond-to(mobile) {
        margin-bottom: 15px;
      }
    }
    &-content {
      font-size: 14px;
    }
  }

}

.back {
  font-size: 11px;
  line-height: 16px;
  color: $main-color-1;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  padding-left: 15px;
  margin-bottom: 30px;
  display: inline-block;
  letter-spacing: 2px;
  &:hover {
    text-decoration: none;
    color: $main-color-1!important;
  }
  &:before {
    content: "";
    position: absolute;
    top: -2px;
    bottom: 0;
    left: 0;
    width: 4px;
    height: 8px;
    margin: auto;
    background: url("images/back.png");
  }
}
