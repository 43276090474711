.hdn_tsa:before {
  content: "TS Advertising Ltd"; }
.hdn_tsa-address:before {
  content: "Tavernier Street, Wall House, Commonwealth of Dominica"; }
.hdn_ms:before {
  content: "Mеdіаstеrrе Ltd (Authorized Agent)"; }
.hdn_ms-address:before {
  content: "79 Spyrou Kyprianou, off 201, 3076, Limassol, Cyprus"; }
.hdn_ms-tsaltd:before {
  content: "TS Advertising Ltd."; }
.hdn_ms-msltd:before {
  white-space: nowrap;
  content: "Mеdіаstеrrе Ltd"; }
.hdn_ms-tsmhk:before {
  content: "TS Marketing Limited"; }
.hdn_ms-reg:before {
  white-space: nowrap;
  content: "НЕ ЗЗЗ622"; }
.hdn_cyp:before {
  content: "Cурrus"; }
.hdn_cyp-rep:before {
  content: "Republic of Cурrus"; }
