.btn{
  display: inline-block;
  padding: 18px 15px 17px 15px;
  font-size: 11px;
  min-width: 170px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  color: #fff !important;
  border-radius: 1px;
  background: $orange;
  border: solid 1px transparent;
  transition: all 200ms;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  @include respond-to(mobile) {
    padding: 14px 15px 13px;
  }

  &:hover {
    text-decoration: none;
    color: $white !important;
    background: #ef7411;
  }

  &:active {
    background: #ef7411;
  }

  &-secondary {
    border: 1px solid $orange;
    background-color: transparent;
    color: $orange !important;
  }

  &-secondary-black {
    border: 1px solid $main-color-2;
    background-color: transparent;
    color: $black !important;

    &:hover {
      background: $gray;
    }
  }

  &-sm {
    min-width: 170px;
    padding: 15px 15px 14px 15px;
    font-size: 16px;
    font-weight: 700;
  }
}
