input, select, textarea {
  color: $black;
  font-size: 14px;
  line-height: 18px;
  display: block;
  width: 100%;
  writing-mode: horizontal-tb;
  background: #FFFFFF;
  border: 1px solid #797979;
  padding: 16px 14px 14px;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
  @include respond-to(mobile) {
    padding: 11px 14px 10px;
  }
}
input {
  &:focus {
    border-color: $orange;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset; // Removing input background colour for Chrome autocomplete
  }
}
option {
  margin: 30px 30px 30px 30px;
  padding: 30px 30px 30px 30px;
}
.field-group, .select-group {
  min-height: 70px;
  position: relative;
}
.select-group {
  &:after {
    content: '';
    position: absolute;
    top: 22px;
    right: 10px;
    width: 11px;
    height: 6px;
    background: url("images/select.svg");
  }
}
.fs14 {
  font-size: 14px !important;
}
.error-text {
  font-size: 10px;
  color: $red;
  margin-top: 5px;
  display: inline-block;
  position: absolute;
}
.error {
  input {
    border-color: $red;
    color: $red;
    background-color: transparent;
  }
  &:after {
    content: '';
    position: absolute;
    top: 18px;
    right: 10px;
    width: 15px;
    height: 15px;
    background: url("images/error.svg");
  }
}
.captcha {
  width: 270px;
  height: 70px;
  background-color: $gray-light;
  margin-bottom: 40px;
  border-radius: 4px;
}


