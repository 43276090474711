.ifb {
  &.-tracker-bonus {
    h2 {
      font-size: 50px;
      line-height: 60px; }
    h3 {
      font-size: 24px;
      line-height: 28px;
      color: #333;
      letter-spacing: .34px;
      font-weight: 600;
      margin-bottom: 20px; }
    .rules {
      text-align: left;
      ol {
          margin-bottom: 25px; }
      li {
          font-size: 16px;
          margin-bottom: 1em; } }
    .btn {
      margin-top: 10px; } } }
