.faq {
  margin-bottom: 60px;
  @include respond-to(desktop) {
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
  }

  &-search-results-count{
    font-size: 30px;
    font-weight: 400;
  }

  &-box {
    @include respond-to(desktop) {
      column-count: 2;
    }
  }

  .qs {
    &_field {
      margin-bottom: 60px;
      @include respond-to(desktop) {
        max-width: 449px;
        margin: 0 auto 60px;
      }
      input {
        padding: 12px 14px 11px;
        background-color: transparent;
        border: 1px solid #F48831;
      }
    }
    &_submit {
      width: 63px;
      height: 43px;
      background-color: $orange;
      position: relative;
      z-index: 1;
      border: 0;
      &:after {
        content: '';
        position: absolute;
        top: 14px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
        width: 16px;
        height: 16px;
        background: url("images/search.svg");

      }
    }
  }
  &-b {
    &_item {
      text-align: left;
      @include respond-to(desktop) {
        padding-right: 20px;
      }
      a {
        position: relative;
        padding-left: 60px;
        display: inline-block;
        text-align: left;
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 36px;
        color: $main-color-2;
        font-weight: 600;
        cursor: pointer;
        transition: 200ms all;
        letter-spacing: 0.341333px;
        @include respond-to(mobile) {
          font-size: 20px;
          line-height: 30px;

        }
        &:before {
          content: '';
          position: absolute;
          top: 5px;
          //bottom: 0;
          left: 0;
          margin: auto;
          width: 30px;
          height: 30px;
          background: url("images/faq.svg");
        }
        &:hover {
          text-decoration: none;
          color: $main-color-2!important;
          &:before {
            background: url("images/faq-on.svg");
          }
        }
      }
    }
  }
  &-open {
    text-align: left;
    margin-bottom: 60px;
    @include respond-to(desktop) {
      max-width: 800px;
      margin: 0 auto 60px;
    }
    h1 {
      @include respond-to(desktop) {
        text-align: center;
        margin-bottom: 30px;
      }
    }
    .back {
      @include respond-to(mobile) {
        display: none;
      }
      &.-mobile {
        @include respond-to(mobile) {
          display: block;
        }
        @include respond-to(desktop) {
          display: none;
        }
      }
    }
  }
}


.ac-container label {
  padding: 15px 20px 10px 15px;
  position: relative;
  display: block;
  z-index: 20;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  color: $main-color-2;
  margin-bottom: 0;
  letter-spacing: 0.341333px;
}
.ac-container label:after,
.ac-container input:checked + label:after {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  top: 25px;
  left: 0;
  border-radius: 50%;
  background-color: $orange;
}
.ac-container input {
  display: none;
}
.ac-container article {
  margin-top: -1px;
  overflow: hidden;
  max-height: 0;
  position: relative;
  z-index: 10;
  //-webkit-transition: max-height 1.9s ease-in-out, box-shadow 0.6s linear;
  //-moz-transition: max-height 1.9s ease-in-out, box-shadow 0.6s linear;
  //-o-transition: max-height 1.9s ease-in-out, box-shadow 0.6s linear;
  //-ms-transition: max-height 1.9s ease-in-out, box-shadow 0.6s linear;
  transition: all .75s ease-in-out;
}
.ac-container article p {
  font-size: 16px;
  line-height: 30px;
  color: $main-color-1;
  margin: 0;
  font-weight: 300;
}
.ac-container input:checked ~ article {
  //-webkit-transition: max-height 1.9s ease-in-out, box-shadow 0.1s linear;
  //-moz-transition: max-height 1.9s ease-in-out, box-shadow 0.1s linear;
  //-o-transition: max-height 1.9s ease-in-out, box-shadow 0.1s linear;
  //-ms-transition: max-height 1.9s ease-in-out, box-shadow 0.1s linear;
  transition: all 1.5s ease-in-out .75s;
}
.ac-container input:checked ~ article {
  max-height: 3000px;
  height: auto;
}
.ac-container article > div {
  padding: 0 0 15px 15px;
}

//.main-content_faq {
//  margin: 0 0 140px;
//}
.main-content_faq .faq-title span {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 40px 0;
  padding: 10px 0;
  background-repeat: repeat;
  display: inline-block;
}
.main-content_faq ol {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #333333;
  margin-left: 40px;
}
.main-content_faq ol li {
  list-style: decimal;
}
.main-content_faq ol li,
.main-content_faq ul li {
  margin-bottom: 10px;
}
.main-content_faq ol li:last-child,
.main-content_faq ul li:last-child {
  margin-bottom: 20px;
}
.main-content_faq ul {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #333333;
  margin-left: 40px;
}
.main-content_faq ul li {
  list-style: disc;
}
