.avs {
  text-align: center;
  .btn {
    margin-top: 40px;
  }
  &-info {
    text-align: left;
    @include respond-to(desktop) {
      max-width: 769px;
      text-align: center;
      margin: 0 auto 40px;
    }
    @include respond-to(mobile) {
      margin-bottom: 30px;
    }
    &_main-text {
      font-size: 16px;
      line-height: 24px;
      opacity: 0.8;
      margin-bottom: 20px;
    }
    &_text {
      font-size: 14px;
      line-height: 21px;
      opacity: 0.8;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .ifb {
    margin-bottom: 0px;
    &_item {
      margin-bottom: 50px;
      &-title {
        font-size: 20px;
        line-height: 30px;
      }
      &-title {
        margin-bottom: 20px;
      }
      &-img {
        margin-bottom: 10px;
      }
    }
  }
  .steps {
    margin-bottom: 60px;
    .btn {
      @include respond-to(xs) {
        width: 100%;
      }
    }
    .abs-title {
      @include respond-to(mobile) {
        text-align: center;
      }
    }
    .s_i {
      max-width: 250px;
      margin: 0 auto;
      @include respond-to(xs) {
        padding-bottom: 80px;
      }
      &.-last {
        @include respond-to(xs) {
          padding-bottom: 0;
        }
      }
      &-arrow {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 30px;
          left: -40px;
          width: 47px;
          height: 24px;
          background: url("images/arrow-large.svg");
          @include respond-to(xs) {
            left: 0;
            right: 0;
            margin: auto;
            top: -50px;
            transform: rotate(90deg);
          }
        }
      }
      &-number {
        font-size: 70px;
        color: $orange;
        margin-bottom: 30px;
        font-weight: 600;
        @include respond-to(xs) {
          margin-bottom: 15px;
        }
      }
      &-text {
        font-size: 20px;
        line-height: 24px;
        color: $main-color-2;
        font-weight: 600;
        letter-spacing: 0.341333px;
      }
    }
  }
}

