.abs {
  margin-bottom: 60px;
  &-title {
    font-size: 24px;
    line-height: 36px;
    color: $main-color-2;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.341333px;
    @include respond-to(mobile) {
      font-size: 20px;
      line-height: 28px;
      text-align: left;
      margin-bottom: 20px;
    }
  }
  &-text {
    font-size: 14px;
    line-height: 28px;
    color: $main-color-3;
    opacity: 0.8;
  }
  &-box {
    @include respond-to(desktop) {
      text-align: center;
      max-width: 763px;
      margin: 0 auto 50px;
    }
    @include respond-to(mobile) {
      margin-bottom: 30px;
    }
    &.-bottom {
      .abs-title {
        margin-bottom: 10px;
      }
    }
  }

  &-ni {
    display: flex;
    align-items: center;
    max-width: 240px;
    @include respond-to(desktop) {
      margin: 0 auto;
    }
    @include respond-to(xs) {
      margin-bottom: 20px;
    }
    &_image {
      position: relative;
      width: 75px;
      height: 75px;
      background: #f48831;
      border-radius: 50%;
      margin-right: 30px;
      position: relative;
      flex: 0 0 75px;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      &.-clients {
        &:after {
          width: 14px;
          height: 39px;
          background: url('images/info-icons/abs-clients.svg') no-repeat;
          background-size: 100%;
        }
      }
      &.-traffic {
        &:after {
          width: 42px;
          height: 45px;
          background: url('images/info-icons/abs-traffic.svg') no-repeat;
          background-size: 100%;
        }
      }
      &.-campaings {
        &:after {
          width: 34px;
          height: 34px;
          background: url('images/info-icons/abs-rocket.svg') no-repeat;
          background-size: 100%;
        }
      }
    }
    &_info {
      span {
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 10px;
        font-weight: 700;
        color: $main-color-3;
        display: block;
      }
      color: $gray-light;
      font-size: 14px;
    }
  }
  &-numbers {
    max-width: 1000px;
    margin: 0 auto 60px;
    @include respond-to(mobile) {
      margin-bottom: 30px;
    }
  }
  &-team {
    margin-bottom: 30px;
    &_item {
      margin-bottom: 30px;
      &-img {
        display: block;
        position: relative;
        padding-top: 70%;
        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
      }
      &-info {
        background-color: $white;
        padding: 25px;
        span {
          font-size: 11px;
          line-height: 16px;
          color: $main-color-2;
          letter-spacing: 2px;
          opacity: 0.5;
          display: block;
          margin-top: 10px;
        }
        font-size: 20px;
        color: $main-color-2;
        font-weight: 700;
      }
    }
  }
}
