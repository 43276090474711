html, body {
  touch-action: manipulation;
  min-width: 320px;
  margin: 0;
  font-size: 10px !important;
  font-family: 'GothamPro', sans-serif;
  line-height: 1.2;
  color: #1A1A1A;
  background-color: #f3f3f3;
  font-weight: 400;
  text-decoration: none;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


h2 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 2px;
  color: #3E3E3E;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.main-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.sf {
    min-height: calc(100vh - 58px) !important;
  }
}

.page-wrapper {
  flex-grow: 1;
  padding-top: 80px;
  padding-bottom: 40px;
  @include respond-to(mobile) {
    padding-top: 90px;
  }
}

// SEO fix
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.page-wrap {
  width: 100%;
  background-color: $white;
}

.table-wrap {
  display: table;
  width: 100%;
}

.align-center {
  text-align: center;
}

.relative {
  position: relative;
}

body {
  position: relative;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

hr {
  height: 0;
  border-color: #eeeeee;
  width: 100%;
  margin: 20px 0;
}

.index-h1 {
  height: 0;
  overflow: hidden;
  position: absolute;
  z-index: -10;
  top: -20px;
}

@mixin animation-delay-none {
  @include transition(none);
}

body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.full-width {
  width: 100%;
  @include clearfix;
}

@include respond-to(desktop) {
  .hidden-desktop {
    display: none !important;
  }
}

a, .link-style {
  color: $orange;
  text-decoration: none;
  display: inline;

  &:focus {
    text-decoration: inherit;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: $orange !important;
  }

}

p {
  margin: 0 0 20px 0;
  color: $main-color-1;
  font-size: 14px;
}

.position-static {
  position: static;
}

html.mobile,
html.tablet {
  .body-overlay {
    cursor: pointer;
  }
}

div.hidden {
  display: none;
}

.fw400 {
  font-weight: 400 !important;
}

body.fixed{
  position: fixed;
}
