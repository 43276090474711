.infobox-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: calc(100vh - 180px);
  flex-direction: column;

  .btn {
    margin-top: 15px; } }

.infobox {
  background-color: $infobox-bg;
  padding: 12px 12px 12px 60px;
  font-size: 14px;
  line-height: 24px;
  color: $orange;
  text-align: left;
  position: relative;

  &:before {
    display: inline-block;
    content: ' ';
    width: 50px;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 36px;
    height: 100%;
    background: #F48831 url("images/icon-info.svg") no-repeat 50%;
    background-size: 20px; } }

.small {
  font-size: 12px !important;
  line-height: 20px !important;
  margin-bottom: 10px !important; }




