.pgw {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  &_btn {
    width: 14px;
    height: 24px;
    cursor: pointer;
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 100%;
      height: 100%;
    }
    &.-prev {
      margin-right: 10px;
      &:after {
        right: 2px;
        background: url("images/arrow-left.png");
        background-size: 100%;
      }
    }
    &.-next {
      margin-left: 10px;
      &:after {
        left: 2px;
        background: url("images/arrow-right.png");
        background-size: 100%;
      }
    }
  }

  &_range {
    display: flex;
  }
  &_item {
    width: 38px;
    height: 38px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #676F79;
    font-size: 16px;
    &:hover {
      text-decoration: none;
      color: #676F79!important;
    }
    &.-current {
      color: #676F79;
      font-weight: bold;
      font-size: 16px;
      border-bottom: 2px solid $orange;
      margin-top: 1px;
    }
  }
}
