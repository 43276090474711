//@mixin for($shortcuts...) {
//  @each $val in $shortcuts {
//    @if $val == $project {
//      @content;
//    }
//  }
//}
/**
  Міксін for приймає n-ну кількість параметрів => 1.
  Приклад:
  @include for(TB2, T4B) {
    color: red;
  }
*/

//Cross browser opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// usage
// .faded-text {
//   @include opacity(0.8);
// }

// =========================================
//Transitions
// http://log.vieron.net/articles/sass-mixins-for-vendor-prefixed-transitions-including-properties/
@function prefix($property, $prefixes: (webkit moz o ms)) {
  $vendor-prefixed-properties: transform background-clip background-size;
  $result: ();
  @each $prefix in $prefixes {
    @if index($vendor-prefixed-properties, $property) {
      $property: -#{$prefix}-#{$property}
    }
    $result: append($result, $property);
    $result: append($result, $property);
  }
  @return $result;
}

@function trans-prefix($transition, $prefix: moz) {
  $prefixed: ();
  @each $trans in $transition {
    $prop-name: nth($trans, 1);
    $vendor-prop-name: prefix($prop-name, $prefix);
    $prop-vals: nth($trans, 2);
    $prefixed: append($prefixed, ($vendor-prop-name $prop-vals), comma);
  }

  @return $prefixed;
}

//========================================
@mixin transition-duration($duration) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}

@mixin transition-delay($delay) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

// =========================================
//Animations and keyframes
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

//usage
// @include keyframes(slide-down) {
//   0% { opacity: 1; }
//   90% { opacity: 0; }
// }
//
// .element {
//   width: 100px;
//   height: 100px;
//   background: black;
//   @include animation('slide-down 5s 3');
// }

// =========================================


// =========================================
// box shadow
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;

}



//===========================================
// generic transform
//https://codepen.io/sawmac/pen/qGudy/
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin($origin) {
  -moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

//================================================================

@mixin clearfix() {
  &:after, &:before {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin sprite-rotate-180 {
  @include transform(rotate(-180deg) scale(1) skew(0deg) translate(0px));
}


@mixin customScrollbar {
  &::-webkit-scrollbar {
    width: 14px;
    background: #fff
  }
  &::-webkit-scrollbar:hover {
    background: #f2f2f2
  }
  &::-webkit-scrollbar-thumb {
    background: #cdcdcd;
    border: 2px solid transparent;
    background-clip: content-box
  }
  &::-webkit-scrollbar-button {
    display: none
  }
}

@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: 1023px) {
      @content;
    }
  }
  @if $media == desktop {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }

  @if $media == xs {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }
  @if $media == sm {
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  }
  @if $media == md {
    @media only screen and (min-width: 1024px) and (max-width: 1339px) {
      @content;
    }
  }
  @if $media == lg {
    @media only screen and (min-width: 1340px) {
      @content;
    }
  }
  @if $media == xs-sm-md {
    @media only screen and (max-width: 1339px) {
      @content;
    }
  }
  @if $media == sm-md-lg {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }
  @if $media == sm-md {
    @media only screen and (min-width: 768px) and (max-width: 1339px) {
      @content;
    }
  }
  @if $media == xs480 {
    @media only screen and (min-width: 480px) and (max-width: 768px) {
      @content;
    }
  }
}

@mixin sourceSansPro() {
  font-family: 'Source Sans Pro', sans-serif;
}

@mixin black($opacity:null) {
  @if $opacity == null {
    background-color: rgba(5, 4, 4, 1);
  }
  @if $opacity != null {
    background-color: rgba(5, 4, 4, $opacity);
  }
}
