@font-face {
  font-family: "GothamPro";
  src: url("fonts/GothamProLight.eot");
  src: url("fonts/GothamProLight.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProLight.woff") format("woff"),
  url("fonts/GothamProLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url("fonts/GothamProRegular.eot");
  src: url("fonts/GothamProRegular.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProRegular.woff") format("woff"),
  url("fonts/GothamProRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GothamPro";
  src: url("fonts/GothamProMedium.eot");
  src: url("fonts/GothamProMedium.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProMedium.woff") format("woff"),
  url("fonts/GothamProMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GothamPro";
  src: url("fonts/GothamProMedium.eot");
  src: url("fonts/GothamProMedium.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProMedium.woff") format("woff"),
  url("fonts/GothamProMedium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "GothamPro";
  src: url("fonts/GothamProBold.eot");
  src: url("fonts/GothamProBold.eot?#iefix")format("embedded-opentype"),
  url("fonts/GothamProBold.woff") format("woff"),
  url("fonts/GothamProBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
